import React from 'react';
import Subscribe from '../Subscribe';
import Image from '../Image';
import './styles.scss';
import imgBannerBg from '../../images/bg-update-banner.jpg';

const SubscribeBanner = () => {
  return (
    <Subscribe
      trigger={
        <div
          style={{
            backgroundImage: `url(${imgBannerBg})`,
            backgroundSize: 'cover',
            width: '100%',
          }}
          className="subscribe-banner"
        >
          <div className="subscribe-banner__text color-white">
            Stay up to date with the latest happenings!
          </div>
          <Image filename="icon-send.svg" />
        </div>
      }
    />
  );
};

export default SubscribeBanner;
